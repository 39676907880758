import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'state/hooks';

import { ProjectAppHeader } from 'features/app_header/AppHeader';
import { NavBar } from 'features/nav_bar/NavBar';
import { Workspace } from 'features/workspace/Workspace';
import './App.css';
import { kUserStatusLoggedIn } from 'state/userSlice';
import { setStateFromSearchParams } from 'state/workflowSlice';
import { dispatchUserStatus } from './AppDispatcher';

export function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userStatus = useAppSelector((state) => state.user.status);
  const initialSearchParams = useAppSelector((state) => state.user.initialSearchParams);

  const [didInitSearchParams, setDidInitSearchParams] = useState(false);

  useEffect(() => {
    dispatchUserStatus(userStatus, dispatch, navigate);
  }, [navigate, dispatch, userStatus]);

  useEffect(() => {
    if (userStatus === kUserStatusLoggedIn && !didInitSearchParams) {
      dispatch(setStateFromSearchParams(initialSearchParams));
      setDidInitSearchParams(true);
    }
  }, [dispatch, userStatus, initialSearchParams, didInitSearchParams]);

  if (userStatus === kUserStatusLoggedIn && didInitSearchParams) {
    return (
      <div className="App">
        <ProjectAppHeader />
        <div className="App-body">
          <NavBar />
          <Workspace />
        </div>
      </div>
    );
  }
}
