import React from 'react';
import { useSelector } from 'react-redux';

import styles from 'features/task_bar/Taskbar.module.css';

import { useGetTypicalSpeedsQuery } from '../../state/apiSlice';
import {
  selectProject,
  selectSegmentId,
  selectTargetDate,
  selectTypicalMonth
} from '../../state/workflowSlice';

export function TypicalMonthAvailabilityWarning() {
  const targetDate: string = useSelector(selectTargetDate);
  const typicalMonth: string = useSelector(selectTypicalMonth);
  const segmentId: number = useSelector(selectSegmentId);
  const project_slug = useSelector(selectProject);

  // typicalData from REST api
  const { currentData: typicalSpeedData } = useGetTypicalSpeedsQuery(
    { project_slug, typicalMonth, segmentId, date: targetDate },
    { skip: !(typicalMonth && segmentId) }
  ) as any;
  let noSpeeds = true;
  if (typicalSpeedData === undefined) {
    return (null);
  }

  if (typicalSpeedData.speeds.length === 0) {
    noSpeeds = true;
  } else {
    for (let i = 0; i < typicalSpeedData.speeds.length; i++) {
      if (typicalSpeedData.speeds[i][2] !== null) {
        noSpeeds = false;
        break;
      }
    }
  }

  if (noSpeeds) {
    return (
      <p className={styles.typical_month_missing_warning}>
        <i>
          Typical data not available for selected segment & month
        </i>
      </p>
    );
  } else {
    return (null);
  }
}
