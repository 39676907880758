import React, { useState, useRef, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {
  selectMaximize,
  selectTypicalMonth,
  selectCapacityThreshold,
  selectBinSize, selectCapacityLanes, selectCurrentProjectInfo
} from 'state/workflowSlice';

import { makeFormatTooltip, planningChartOptions } from 'features/chart/planningChartOptions';
import { PlanningChartDataExpander } from 'features/chart/PlanningChartDataExpander';

import { BIN_60_MINS } from 'appConstants';

import styles from 'features/chart/Chart.module.css';
import { usePlanningVolumesDataProvider } from '../workflow_planning/planningVolumesCommon';
import { graph_highlight_color } from './chartOptions';

function formatVolumeSeriesName(typicalMonth, has_volume_model) {
  return `${dayjs(typicalMonth, 'MMMM-YYYY').format('MMM \'YY')} ${has_volume_model ? 'volume' : 'trip count'}`;
}

export function PlanningChartVolume() {
  const maximize = useSelector(selectMaximize);
  const capacityThreshold = useSelector(selectCapacityThreshold);
  const capacityLanes = useSelector(selectCapacityLanes);
  const binSize = useSelector(selectBinSize);
  const userProject = useSelector(selectCurrentProjectInfo);

  const typicalMonth = useSelector(selectTypicalMonth);

  // normalize capacityThreshold to binSize
  const binCapacityThreshold = useMemo(
    () => (capacityThreshold * capacityLanes),
    [capacityThreshold, capacityLanes, binSize]
  );

  const chartComponent = useRef(null); // so we can call reflow

  const [chartOptions, setChartOptions] = useState(planningChartOptions as any);

  const planningVolumeData = usePlanningVolumesDataProvider();

  // memoize chartDataExpander
  const chartDataExpander = useMemo(() => new PlanningChartDataExpander(binSize), [binSize]);

  // memoize expanded segmentSpeeds
  const segmentVolumes = useMemo(
    () => chartDataExpander?.expandPlanningData(planningVolumeData),
    [planningVolumeData, chartDataExpander]
  );

  // memoize thresholdVolumeFlatline
  const thresholdCapacityFlatline = useMemo(
    () => chartDataExpander?.flatline(binCapacityThreshold),
    [binCapacityThreshold, chartDataExpander]
  );

  // memoize thresholdBands
  const thresholdBands = useMemo(
    () => chartDataExpander?.volumeThresholdBands(segmentVolumes, binCapacityThreshold, graph_highlight_color),
    [segmentVolumes, binCapacityThreshold, chartDataExpander]
  );

  // update chart with new targetDate, freeflowSpeeds, segmentSpeeds
  useEffect(() => {
    setChartOptions({
      yAxis: {
        title: {
          text: (userProject?.has_volume_model) ? 'Hourly volume' : 'Trip count',
        },
      },
      xAxis: {
        plotBands: thresholdBands,
      },
      series: [
        {
          id: 'capacity_threshold_flatline',
          data: thresholdCapacityFlatline,
          marker: { enabled: false },
          name: (binSize === BIN_60_MINS) ? 'Capacity Threshold' : 'Capacity Threshold',
        },
        {
          id: 'typical_volume',
          data: segmentVolumes,
          marker: { enabled: false },
          name: formatVolumeSeriesName(typicalMonth, userProject?.has_volume_model)
        },
      ],
      tooltip: {
        formatter: makeFormatTooltip(
          parseFloat(binSize) * 60,
          thresholdBands
        )
      }
    });
  }, [segmentVolumes, thresholdCapacityFlatline, binSize, thresholdBands]);

  useEffect(() => {
    const chart = chartComponent.current?.chart;
    if (chart) {
      chart.reflow();
    }
  }, [maximize]);

  return (
    <div className={styles.chart}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        options={chartOptions}
      />
    </div>
  );
}
