export function arrayEquals(a, b) {
  return Array.isArray(a)
    && Array.isArray(b)
    && a.length === b.length
    && a.every((val, index) => val === b[index]);
}

export function deepEquals(a, b) {
  if (a === b) {
    return true;
  } else if (Array.isArray(a) && Array.isArray(b)) {
    return Array.isArray(a)
      && Array.isArray(b)
      && a.length === b.length
      && a.every((val, index) => deepEquals(val, b[index]));
  } else if (Object.keys(a) && Object.keys(b)) {
    return arrayEquals(Object.keys(a), Object.keys(b))
      && Object.keys(a).every((val) => deepEquals(a[val], b[val]));
  }
  return false;
}

export const MILES_TO_KM = 1.60934;
export const KM_TO_MILES = 1 / MILES_TO_KM;

export function speedUnit(uses_metric: boolean) {
  return uses_metric ? 'kph' : 'mph';
}

export function convertSpeeds(value: number, uses_metric: boolean) {
  if (!value) {
    return value;
  }
  if (uses_metric) {
    return value * MILES_TO_KM;
  } else {
    return value;
  }
}

export function formatSpeed(value: string | number, uses_metric: boolean, value_already_converted:boolean = false) {
  const nValue = value_already_converted ? Number(value) : convertSpeeds(Number(value), uses_metric);
  return `${nValue.toFixed(0)} ${speedUnit(uses_metric)}`;
}

export function formatDistance(value_meters: number, uses_metric: boolean) {
  if (uses_metric) {
    return `${(value_meters / 1000).toFixed(1)} km`;
  } else {
    return `${(value_meters / (1000 * MILES_TO_KM)).toFixed(1)} mi`;
  }
}

export function posMod(v: number, mod: number): number {
  return ((v % mod) + mod) % mod;
}
