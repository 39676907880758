import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LayerContext } from 'state/LayerContext';

import {
  selectMaximize,
  selectProject, selectTargetDate, getSpecificWorkflowState, getWorkflowState
} from 'state/workflowSlice';
import { useGetDatesQuery } from 'state/apiSlice';

import { TaskBar } from 'features/task_bar/TaskBar';
import { workflowStyles } from 'features/workspace/workspaceUtils';
import { WORKFLOW_SPEED, WORKFLOW_TIMELINE, WORKFLOW_TRAVEL_TIME } from 'appConstants';
import styles from 'features/workspace/Workspace.module.css';

import { NRTDatePicker } from '../task_bar/DatePicker';
import { TimeSlider } from './TimeSlider';
import { TimelineMap } from './TimelineMap';
import { TimelineMetricsOption } from './TimelineMetricsOption';
import { RealtimeDataProvider } from './RealtimeDataProvider';

export class workflowTimeline {
  switchToState(fromWorkflow, state) {
    // console.log('Handling switch from ', fromWorkflow, 'to', state.current, state);
    if ([WORKFLOW_TIMELINE, WORKFLOW_SPEED, WORKFLOW_TRAVEL_TIME].includes(fromWorkflow)) {
      getWorkflowState(state).targetDate = getSpecificWorkflowState(state, fromWorkflow).targetDate;
      getWorkflowState(state).comparison = getSpecificWorkflowState(state, fromWorkflow).comparison;
    }
  }
}

export function WorkflowTimeline() {
  const maximize = useSelector(selectMaximize);
  const project = useSelector(selectProject);

  const { map_style } = workflowStyles(maximize);

  // ======================

  const targetDate = useSelector(selectTargetDate);
  const { data: datesInfo } = useGetDatesQuery(project);
  const [layer, setLayer] = useState(null);
  const dispatch = useDispatch();

  // memoize so this doesn't change every render.
  const memoizedLayer = useMemo(
    () => ({ layer, setLayer }),
    [layer]
  );

  useEffect(() => {
    if (datesInfo) {
      if (targetDate) {
        const new_layer = datesInfo && targetDate ? datesInfo.find(
          (info) => info.date === targetDate
        )?.layer : null;
        // console.log(`WorkflowSpeed set new layer ${new_layer} for targetDate ${targetDate}`);
        setLayer(new_layer);
      }
    }
  }, [datesInfo, targetDate, setLayer, dispatch]);

  // ======================

  return (
    <LayerContext.Provider value={memoizedLayer}>
      <div className={styles.workspace_container}>
        <div className={styles.workspace_content}>
          <div className={map_style}>
            <TimelineMap />
          </div>
          <TimeSlider />
        </div>
        <TaskBar
          segmentInfo={false}
        >
          <NRTDatePicker enableDayArrows />
          <RealtimeDataProvider />
          <TimelineMetricsOption />
        </TaskBar>

      </div>

    </LayerContext.Provider>
  );
}
