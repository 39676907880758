import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { setWorkflow, selectWorkflow, selectCurrentProjectInfo } from 'state/workflowSlice';

import {
  WORKFLOW_PLAN_NAMES,
  WORKFLOWS_ALL_ENABLED
} from 'appConstants';

import styles from './NavBar.module.css';
import { selectUserState } from '../../state/userSlice';

export function NavBar() {
  const workflow = useSelector(selectWorkflow);
  const userProject = useSelector(selectCurrentProjectInfo);
  const userInfo = useSelector(selectUserState);
  const dispatch = useDispatch();
  const disableDisabledFeatures = !userInfo.has_hidden_features;

  let enabledWorkflows = userProject?.features ? WORKFLOWS_ALL_ENABLED
    .filter((value) => userProject.features.includes(value)) : [];

  if (!disableDisabledFeatures) {
    enabledWorkflows = WORKFLOWS_ALL_ENABLED;
  }

  enabledWorkflows = enabledWorkflows.sort((a, b) => {
    const a_enabled = userProject?.features.includes(a);
    const b_enabled = userProject?.features.includes(b);
    if ((a_enabled && b_enabled) || (!a_enabled && !b_enabled)) {
      return 0;
    } else if (a_enabled && !b_enabled) {
      return -1;
    } else {
      return 1;
    }
  });

  useEffect(() => {
    if (!enabledWorkflows.includes(workflow)) {
      dispatch(setWorkflow(enabledWorkflows[0]));
    }
  }, [workflow, userProject]);

  return (
    <div className={styles.nav_bar}>
      <List disablePadding>
        {enabledWorkflows.map((workflow_name, index) => {
          const isIncludedWorkflow = userProject?.features.includes(workflow_name);
          const unchosenClass = isIncludedWorkflow ? styles.nav_button : styles.nav_button_disabled;
          return (
            <ListItem
              key={workflow_name}
              disablePadding
              className={workflow === workflow_name ? styles.nav_button_chosen : unchosenClass}
            >
              <ListItemButton
                selected={workflow === workflow_name}
                onClick={(e) => {
                  e.currentTarget.blur();
                  dispatch(setWorkflow(workflow_name));
                }}
                className={styles.list_item_button}
              >
                <ListItemText
                  primary={WORKFLOW_PLAN_NAMES[workflow_name]}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
