import React, { useState } from 'react';

import { Button } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { ErrorBoundary } from 'react-error-boundary';
import { useRouteError } from 'react-router-dom';

import './App.css';
import { useDispatch } from 'react-redux';
import { persistor, store } from '../state/store';

export function ErrorPage({ resetErrorBoundary }: { resetErrorBoundary?: Function }) {
  const error: any = useRouteError();
  const hardRefresh = (e) => {
    store.dispatch({ type: 'RESET' });
    persistor.purge();
    window.history.replaceState(undefined, undefined, '/');
    window.location.reload();
  };

  const softRefresh = (e) => {
    window.history.replaceState(undefined, undefined, '/');
    window.location.reload();
  };

  return (
    <div className="error-page">
      <h1>Something went wrong...</h1>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <div>
        <Button className="retry-button" onClick={softRefresh}>
          <Refresh />
          Perform soft refresh (try first)
        </Button>
      </div>
      <div>
        <Button className="retry-button" onClick={hardRefresh} color="error">
          <Refresh />
          Perform hard refresh
        </Button>
      </div>
      <p className="error-stack-detail">
        {error.stack}
      </p>
    </div>
  );
}

export function ReactErrorBoundary({ children }: React.PropsWithChildren) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error, errorInfo) => {
        // log the error
        console.error(error);
        console.error(errorInfo);
      }}
      onReset={() => {
        // reloading the page to restore the initial state
        // of the current page
        console.log('reloading the page...');
        window.location.reload();

        // other reset logic...
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export function useCallbackWithErrorHandling(callback) {
  const [state, setState] = useState();

  return (...args) => {
    try {
      callback(...args);
    } catch (e) {
      setState(() => {
        throw e;
      });
    }
  };
}

export function ErrorTrigger() {
  return (
    <Button onClick={useCallbackWithErrorHandling((e) => (undefined as any).test)}>
      Break!
    </Button>
  );
}
