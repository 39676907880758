import Highcharts from 'highcharts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  baseChartOptions,
  getBandMembership,
  grid_color,
  label_color,
  axis_color,
  font_size,
  recent_speed_color, graph_highlight_color, freeflow_color
} from './chartOptions';

dayjs.extend(utc);

const capacity_threshold_color = freeflow_color; // green
const typical_color = recent_speed_color;
const TICK_INTERVAL_HOUR = 1000 * 60 * 60; // milliseconds * seconds * minutes

// highcharts-react npm page
// https://www.npmjs.com/package/highcharts-react-official

// highcharts-react github repo
// https://github.com/highcharts/highcharts-react#readme

// highcharts documentation
// https://api.highcharts.com/highcharts/

export function getDayForTs(value) {
  const days = ['Mon-Fri', 'Saturday', 'Sunday'];
  const day_n = Math.min(Math.floor((value as number) / (TICK_INTERVAL_HOUR * 24)), (days.length - 1));
  return days[day_n];
}

function getFormattedTime(ts) {
  return dayjs(ts).utc().format('HH:mm');
}

export function makeFormatTooltip(bin_width_sec, bands) {
  function formatTooltip(this: Highcharts.TooltipFormatterContextObject, tooltip: Highcharts.Tooltip) {
    const ts = this.x;
    const day = getDayForTs(ts);
    const date = getFormattedTime(ts);
    const band = getBandMembership(ts, bands);
    const end_time = getFormattedTime((ts as number) + (bin_width_sec * 1000));
    function safeToFixed(num) {
      if (num !== undefined && num !== null && Number.isFinite(num)) {
        return num.toFixed(0);
      } else {
        return 'Unknown';
      }
    }

    let output = `
        <div class="chart-legend" style="font-size: 15px;">
            <h1>${date} - ${end_time}</h1>
            <table>`;
    if (band) {
      output += `
      <tr>
        <td>
            <span class="highlight-icon" style="color:${graph_highlight_color}">◼</span>Highlight
        </td>
        <td>
            <b>${getFormattedTime(band.raw_from)} - ${getFormattedTime(band.raw_to)}</b>
        </td>
      </tr>`;
    }
    output += `
        <tr>
            <td>
                <span style="color:${this.points[0].series.color}">●</span>${this.points[0].series.name}
            </td>
            <td>
                <b>${safeToFixed(this.points[0]?.y)}</b>
            </td>
        </tr>`;
    return `${output}</table></div>`;
  }
  return formatTooltip;
}

export const planningChartOptions = {
  ...baseChartOptions,
  time: {
    useUTC: true,
  },
  chart: {
    ...baseChartOptions.chart,
    marginRight: '170',
  },
  yAxis: {
    min: 0,
    lineColor: axis_color,
    gridLineColor: grid_color,
    gridLineWidth: 1,
    lineWidth: 1,
    tickWidth: 1,
    title: {
      text: 'Volume',
      style: {
        color: label_color,
        fontSize: font_size,
      },
    },
    labels: {
      style: {
        color: label_color,
        fontSize: font_size,
      },
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%H:%M',
    },
    minPadding: 0,
    maxPadding: 0,
    // startOnTick: true,
    endOnTick: true,
    lineColor: axis_color,
    gridLineColor: grid_color, // vertical lines
    minorGridLineColor: grid_color,
    gridLineWidth: 1,
    tickPixelInterval: 50,
    tickInterval: TICK_INTERVAL_HOUR * 6,
    minorTickInterval: TICK_INTERVAL_HOUR,
    labels: {
      formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
        let label = this.axis.defaultLabelFormatter.call(this);
        const day = getDayForTs(this.value);
        if (label === '12:00') { // Add the day on a new line when the label is mid day
          label += `<br/>${day}`;
        }
        return label;
      },
      style: {
        color: label_color,
        fontSize: font_size,
      },
      rotation: 0,
    },
    plotLines: [{
      width: 2,
      value: TICK_INTERVAL_HOUR * 24,
    },
    {
      width: 2,
      value: 2 * TICK_INTERVAL_HOUR * 24,
    }]
  },
  series: [
    {
      id: 'capacity_threshold_flatline',
      data: [],
      name: 'Capacity',
      color: capacity_threshold_color,
      lineWidth: 2,
      marker: { enabled: false },
    },
    {
      id: 'typical_volume',
      type: 'spline',
      data: [],
      name: 'Typical Volume',
      color: typical_color,
      lineWidth: 2,
      marker: { enabled: false, symbol: 'circle' },
    },
  ],
  legend: {
    ...baseChartOptions.legend,
    itemWidth: 150,
  },
};
