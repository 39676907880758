import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { displayTypicalMonth, sortTypicalMonths } from 'appUtils';
import { useGetPlanningPeriodsQuery } from 'state/apiSlice';

import { setTypicalMonth, selectTypicalMonth, selectProject } from 'state/workflowSlice';

import { OverflowLabel } from './OverflowLabel';
import { TaskBarComponent } from './TaskBar';
import { BaseMonthPicker } from './TypicalMonthPicker';

export function PlanningMonthPicker() {
  return (
    <TaskBarComponent>
      <OverflowLabel id="planning-month-input-label">
        Period: &nbsp;
        <BaseMonthPicker querier={useGetPlanningPeriodsQuery} />
      </OverflowLabel>
    </TaskBarComponent>
  );
}
