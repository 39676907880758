import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from 'features/task_bar/Taskbar.module.css';
import { ToggleGroup } from 'features/common/ToggleGroup';
import { GenericBaseMonthPicker } from './TypicalMonthPicker';
import { GenericNRTDatePicker } from './DatePicker';
import { TaskBarComponent } from './TaskBar';
import { useGetTypicalMonthsQuery } from '../../state/apiSlice';
import { OverflowLabel } from './OverflowLabel';
import { COMPARISON_MODE_DAY, COMPARISON_MODE_MONTH } from '../../appConstants';
import { selectComparison, selectTargetDate, setComparison } from '../../state/workflowSlice';

export function isComparisonMonth(comp: string) {
  if (!comp) {
    return false;
  }
  if (comp.split('-').length === 2) {
    return true;
  }
  return false;
}

export function comparisonToComparisonType(comp: string) {
  if (isComparisonMonth(comp)) {
    return COMPARISON_MODE_MONTH;
  } else {
    return COMPARISON_MODE_DAY;
  }
}

export function ComparisonPicker() {
  const dispatch = useDispatch();
  const targetDate = useSelector(selectTargetDate);
  const comparison = useSelector(selectComparison);
  const [comparisonType, setComparisonType] = useState(comparisonToComparisonType(comparison));
  let defaultComparisonDay;
  if (comparison && !isComparisonMonth(comparison)) {
    defaultComparisonDay = comparison;
  }
  let defaultComparisonMonth;
  if (comparison && isComparisonMonth(comparison)) {
    defaultComparisonMonth = comparison;
  }
  const [comparisonDay, setComparisonDay] = useState(defaultComparisonDay);
  const [comparisonMonth, setComparisonMonth] = useState(defaultComparisonMonth);

  useEffect(() => {
    if (comparison) {
      setComparisonType(comparisonToComparisonType(comparison));
      if (isComparisonMonth(comparison)) {
        setComparisonMonth(comparison);
      } else {
        setComparisonDay(comparison);
      }
    } else {
      setComparisonType(COMPARISON_MODE_MONTH);
      dispatch(setComparison(comparisonMonth));
    }
  }, [comparison]);

  useEffect(() => {
    if (comparisonType === COMPARISON_MODE_MONTH) {
      dispatch(setComparison(comparisonMonth));
    } else if (comparison !== comparisonDay) {
      dispatch(setComparison(comparisonDay));
    }
  }, [comparisonType, comparisonDay, comparisonMonth]);

  return (
    <TaskBarComponent>
      <OverflowLabel>Comparison:</OverflowLabel>
      <ToggleGroup
        handleClick={(event: any) => { setComparisonType(event.target.value); }}
        value={comparisonType}
        values={[COMPARISON_MODE_MONTH, COMPARISON_MODE_DAY]}
        label_map={{ [COMPARISON_MODE_MONTH]: 'Month', [COMPARISON_MODE_DAY]: 'Day' }}
        horizontal
      />
      <div className={styles.task_bar_vpad_component}>
        { comparisonType === COMPARISON_MODE_MONTH
          && (
            <GenericBaseMonthPicker
              querier={useGetTypicalMonthsQuery}
              value={comparisonMonth}
              setValue={(v) => { setComparisonMonth(v); }}
            />
          ) }
        { comparisonType === COMPARISON_MODE_DAY
          && (
            <GenericNRTDatePicker
              targetDate={comparisonDay}
              setNewTargetDate={(value) => setComparisonDay(value)}
              fullWidth
              forbiddenDates={[targetDate]}
            />
          ) }
      </div>
    </TaskBarComponent>
  );
}
