import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectInfoForSlug, selectUser } from 'state/userSlice';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { setProjectSlug, selectProject } from '../../state/workflowSlice';
import styles from './AppHeader.module.css';
// import info_hover_styles from '../info_hover/InfoHover.module.css';

export function ProjectSwitcher() {
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const dispatch = useDispatch();

  const setSlug = (newProject) => {
    const projectInfo = getProjectInfoForSlug(user, newProject);
    if (!projectInfo) console.log('Project info not found for slug, resorting to default project');
    if ((!newProject) || (!projectInfo)) {
      for (let i = 0; i < user.projects.length; i++) {
        if (user.projects[i].is_default) {
          const default_project = user.projects[i].project_slug;
          console.log(`Setting project slug to be ${default_project} as default`);
          newProject = default_project;
          break;
        }
      }
      if (!newProject && user.projects.length > 0) {
        newProject = user.projects[0].project_slug;
      }
    }
    if (newProject !== project) {
      dispatch(setProjectSlug(newProject));
    }
  };

  const handleChange = (event) => {
    console.debug(event);
    const newProject = event.target.value;
    console.log(`ProjectSwitcher handleChange newProject: ${newProject}`);
    setSlug(newProject);
  };
  // console.log(user);

  useEffect(() => {
    if (user.projects !== undefined) {
      setSlug(project);
    }
  }, [user, project, setSlug]);

  if (user.projects !== undefined && project) {
    if (user.projects.length > 1) {
      return (
        <div className={styles.project_name_container}>
          <Tooltip title="Select a project" placement="right-end">
            <Select
              size="small"
              onChange={handleChange}
              className={styles.project_name}
              value={project}
            >
              {user.projects.map((option) => (
                <MenuItem key={option.project_slug} value={option.project_slug}>
                  {option.project_name}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
        </div>
      );
    } else {
      return (
        <h1 className={styles.project_name}>{getProjectInfoForSlug(user, project)?.project_name}</h1>
      );
    }
  } else {
    return (null);
  }
}
