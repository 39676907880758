import React from 'react';

import { useSelector } from 'react-redux';

import noRouteSelected from './Route_selection_instructions.png';
import { selectSegmentId } from '../../state/workflowSlice';
import { BaseNoSegmentSelectedPanel } from '../workspace/NoSegmentSelectedPanel';

export function NoRouteSelectedPanel() {
  const segmentId = useSelector(selectSegmentId);
  if (segmentId === undefined) {
    return (
      <BaseNoSegmentSelectedPanel>
        <img
          style={{
            maxHeight: '70%',
            maxWidth: '50vw',
            minWidth: '300px'
          }}
          src={noRouteSelected}
          alt="To select a route, first select a single segment as usual then hold ctrl for future selections"
        />
      </BaseNoSegmentSelectedPanel>
    );
  } else {
    return undefined;
  }
}
