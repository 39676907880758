import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  selectComparison,
  selectProject,
  selectRouteSelection,
  selectSegmentId, selectTargetDate,
} from '../../state/workflowSlice';
import { LayerContext } from '../../state/LayerContext';
import {
  useGetTypicalRouteTravelTimeQuery, useGetRouteTravelTimeQuery, useGetLayerQuery
} from '../../state/apiSlice';
import { getRouteForSparseSegments } from '../map/mapUtils';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';

export function useTravelTimeDataProvider() {
  const project_slug = useSelector(selectProject);
  const targetDate: string = useSelector(selectTargetDate);
  const segmentId = useSelector(selectSegmentId);
  const routeSelection = useSelector(selectRouteSelection);
  const comparison = useSelector(selectComparison);

  const { layer } = useContext(LayerContext);

  // Get data for Route volumes
  const { currentData: layerData, } = useGetLayerQuery(layer, { skip: !layer });

  const isMonthComparison = comparison && isComparisonMonth(comparison);

  let routeSegments;
  if (routeSelection && layerData?.routing) {
    routeSegments = getRouteForSparseSegments(layerData.routing, routeSelection);
  } else if (segmentId) {
    routeSegments = [segmentId];
  }

  const { currentData: ttData } = useGetRouteTravelTimeQuery(
    { project_slug, date: targetDate, routeIds: routeSegments },
    { skip: !(targetDate && routeSegments) }
  ) as any;

  const { currentData: typicalTtData } = useGetTypicalRouteTravelTimeQuery(
    { project_slug, typicalMonth: comparison, routeIds: routeSegments, date: targetDate },
    { skip: !(comparison && routeSegments) || !isMonthComparison }
  ) as any;

  const { currentData: comparisonNrtData } = useGetRouteTravelTimeQuery(
    { project_slug, date: comparison, routeIds: routeSegments },
    { skip: !(comparison && routeSegments) || isMonthComparison }
  ) as any;

  const comparisonData = typicalTtData || comparisonNrtData;

  return {
    ttData,
    comparisonData
  };
}
