import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LayerContext } from 'state/LayerContext';
import { selectMaximize, selectProject, selectSegmentId } from 'state/workflowSlice';

import { selectTargetDate } from 'state/workflowSlice';
import { useGetDatesQuery } from 'state/apiSlice';

import { Map } from 'features/map/Map';
import { ChartHeader } from 'features/workflow_nrt/ChartHeader';

import { workflowStyles } from 'features/workspace/workspaceUtils';
import { TaskBar } from 'features/task_bar/TaskBar';

import { ChartSettings } from 'features/workflow_nrt/ChartSettings';

import styles from 'features/workspace/Workspace.module.css';
import { NoSegmentSelectedPanel } from '../workspace/NoSegmentSelectedPanel';

function VisualisationRegion(
  { children, graph_title, unselected_state_fn }:
    { children: React.ReactNode, graph_title: string, unselected_state_fn: () => (React.ReactNode | undefined) }
) {
  const maximize = useSelector(selectMaximize);

  const { data_viz_style, header_style } = workflowStyles(maximize);

  const unselected_state = unselected_state_fn();
  if (unselected_state) {
    return unselected_state as any;
  }

  return (
    <div className={data_viz_style}>
      <div className={header_style}>
        <ChartHeader
          graph_title={graph_title}
        />
      </div>
      <div className={styles.chart_container_inner}>
        {children}
      </div>
    </div>
  );
}

export function WorkflowRecent(
  {
    chart,
    graph_title,
    slowdown_tooltip,
    enableRouteSelection = false,
    segmentInfoExtras = undefined,
    unselected_chart_state_fn = undefined
  }:
    {
      chart: React.ReactNode,
      graph_title: string,
      slowdown_tooltip: string,
      enableRouteSelection?: boolean,
      segmentInfoExtras? : Array<React.ReactNode>,
      unselected_chart_state_fn?: () => (React.ReactNode | undefined)
    }
) {
  const maximize = useSelector(selectMaximize);
  const project = useSelector(selectProject);

  // ======================

  const targetDate = useSelector(selectTargetDate);
  const { data: datesInfo } = useGetDatesQuery(project);
  const [layer, setLayer] = useState(null);
  const dispatch = useDispatch();

  // memoize so this doesn't change every render.
  const memoizedLayer = useMemo(
    () => ({ layer, setLayer }),
    [layer]
  );

  useEffect(() => {
    if (datesInfo) {
      if (targetDate) {
        const new_layer = datesInfo && targetDate ? datesInfo.find(
          (info) => info.date === targetDate
        )?.layer : null;
        console.log(`WorkflowSpeed set new layer ${new_layer} for targetDate ${targetDate}`);
        setLayer(new_layer);
      }
    }
  }, [datesInfo, targetDate, setLayer, dispatch]);

  // ======================

  const { map_style } = workflowStyles(maximize);

  return (
    <LayerContext.Provider value={memoizedLayer}>
      <div className={styles.workspace_container}>
        <div className={styles.workspace_content}>
          <div className={map_style}>
            <Map
              enableRouteSelection={enableRouteSelection}
            />
          </div>
          <VisualisationRegion
            graph_title={graph_title}
            unselected_state_fn={unselected_chart_state_fn || NoSegmentSelectedPanel}
          >
            {chart}
          </VisualisationRegion>
        </div>
        <TaskBar
          segmentInfoExtras={segmentInfoExtras}
        >
          <ChartSettings
            slowdownTooltip={slowdown_tooltip}
          />
        </TaskBar>
      </div>
    </LayerContext.Provider>
  );
}
