import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'state/hooks';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import 'app/App.css';

import { checkUserStatus, loginUser } from 'state/userSlice';

import styles from './Login.module.css';
import { UserViewBase } from './UserViewBase';
import { isObject } from '../../appUtils';

// TODO rather than rolling our own, maybe we should follow:
// https://www.bezkoder.com/react-hooks-redux-login-registration-example/

function LoginDialog() {
  const dispatch = useDispatch();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const errors = useAppSelector((state) => state.user.errors);

  const [mergedErrors, setMergedErrors] = useState({ ...errors });

  // const canSubmit = username != '' && password != ''
  const canSubmit = true;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (canSubmit) {
      try {
        // @ts-ignore
        // ! TS2345: Argument of type 'AsyncThunkAction<any, { username: string; password: string; }, AsyncThunkConfig>' is not assignable to parameter of type 'AnyAction'.
        const originalPromiseResult = await dispatch(loginUser({ username, password })).unwrap();

        // @ts-ignore
        // ! TS2345: Argument of type 'AsyncThunkAction<any, { username: string; password: string; }, AsyncThunkConfig>' is not assignable to parameter of type 'AnyAction'.
        dispatch(checkUserStatus());

        // console.log(`login for ${username} fullfilled: ${JSON.stringify(originalPromiseResult)}`);
      } catch (rejectedValueOrSerializedError) {
        console.log(`login for ${username} failed with error: ${JSON.stringify(rejectedValueOrSerializedError)}`);
        if (isObject(rejectedValueOrSerializedError.error)) {
          setMergedErrors(rejectedValueOrSerializedError.error);
        } else {
          setMergedErrors({ non_field_errors: rejectedValueOrSerializedError.error });
        }
      }
    }
  };

  if (mergedErrors) {
    console.log(mergedErrors);
  }

  return (
    <div className={styles.login_container}>
      <div className={styles.login}>
        <h1>Please Log In</h1>
        <div className={styles.login_error_message}>{mergedErrors.non_field_errors}</div>

        <div>
          <form onSubmit={handleSubmit}>
            <InputLabel htmlFor="username">
              <div className={styles.login_field_name}>Email</div>
              <TextField type="text" variant="outlined" size="small" id="username" onChange={(e) => setUserName(e.target.value)} />
              <div className={styles.login_field_error_message}>{mergedErrors.username}</div>
            </InputLabel>
            <InputLabel htmlFor="password">
              <div className={styles.login_field_name}>Password</div>
              <TextField type="password" variant="outlined" size="small" id="password" onChange={(e) => setPassword(e.target.value)} />
              <div className={styles.login_field_error_message}>{mergedErrors.password}</div>
            </InputLabel>
            <p>
              <Button variant="contained" className={styles.submit_button} disabled={!canSubmit} type="submit">
                Submit
              </Button>
            </p>
            <p><Link href="/server/password-reset/">Forgotten your password?</Link></p>
          </form>
        </div>
      </div>
    </div>
  );
}

export function Login() {
  return (
    <UserViewBase>
      <div className="App-body"><LoginDialog /></div>
    </UserViewBase>
  );
}
