import { workflowSpeed } from 'features/workflow_nrt/WorkflowSpeed';
import { WORKFLOW_SPEED, WORKFLOW_TIMELINE, WORKFLOW_TRAVEL_TIME } from '../../appConstants';
import { workflowTimeline } from '../workflow_timeline/WorkflowTimeline';
import { workflowTravelTime } from '../workflow_traveltime/WorkflowTravelTime';

export const workflowLookup = {
  [WORKFLOW_TIMELINE]: workflowTimeline,
  [WORKFLOW_SPEED]: workflowSpeed,
  [WORKFLOW_TRAVEL_TIME]: workflowTravelTime,
};
