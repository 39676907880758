import React from 'react';
import { useSelector } from 'react-redux';
import Slider from '@mui/material/Slider';
import { SxProps } from '@mui/material';
import Color from 'colorjs.io';

import { optionallyCall } from 'appUtils';
import { arrayEquals } from 'features/common/utils';
import { selectCurrentProjectInfo } from 'state/workflowSlice';

import styles from './MapLegend.module.css';
import { TL_METRIC_OPTIONS } from './metricsOptions';
import { null_grey, primary_text_color, stl_light_grey, transparent_null_grey } from '../../theme/cemTheme';
import { COLORMAP_MINUS_1_GREY, ColorGradient } from '../chart/colorMaps';
import { kMainVisLineOpacity } from '../map/mapCommon';

const legendSx: SxProps = {
  cursor: 'default !important',
  paddingTop: '0',
  '.MuiSlider-root': {
    cursor: 'default !important'
  },
  '.MuiSlider-rail': {
    height: '0.8em',
    background: 'none',
    opacity: kMainVisLineOpacity,
    borderRadius: 0
  },
  '.MuiSlider-thumb': {
    display: 'none'
  },
  '.MuiSlider-track': {
    display: 'none'
  },
  '.MuiSlider-markLabel': {
    color: primary_text_color,
    top: 'calc(0.8em + 5px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'middle',
    '&[style="left: 0%;"]': {
      transform: 'translateX(0%)',
      alignItems: 'start'
    },
    '&[style="left: 100%;"]': {
      transform: 'translateX(-100%)',
      alignItems: 'end'
    }
  },
  '.MuiSlider-mark': {
    height: '0',
    backgroundColor: stl_light_grey,
    transform: 'translateY(200%)',
  }
};

export function TimelineLegend({ metric, hourlyMaxCount }: {metric: string, hourlyMaxCount: number}) {
  const userProject = useSelector(selectCurrentProjectInfo);

  const setting = TL_METRIC_OPTIONS[metric];
  const renderArgs = { hourlyMaxCount, uses_metric: userProject.uses_metric };

  if (!setting) {
    return (null);
  }

  let colors = [...optionallyCall(setting.colors, renderArgs)];
  if (arrayEquals(colors.slice(0, COLORMAP_MINUS_1_GREY.length), COLORMAP_MINUS_1_GREY)) {
    colors = colors.slice(COLORMAP_MINUS_1_GREY.length);
  }

  const colormap_obj = new ColorGradient(colors);

  const stops = colormap_obj.stops();
  const min_val = Math.min(...stops);
  const max_val = Math.max(...stops);

  let legendBackground = 'linear-gradient(0.25turn';
  for (let i = 0; i < colormap_obj.stop_colors.length; i++) {
    const stop = colormap_obj.stop_colors[i];
    const perc = (100 * ((stop.stop - min_val) / (max_val - min_val)));
    legendBackground += `, ${stop.color.toString({ format: 'hex' })} ${perc.toFixed(3)}%`;
  }
  legendBackground += ')';

  const rawLegendStops = optionallyCall(setting.legend_stops, renderArgs);
  const legend_stops = [];
  let any_tall_legends = false;
  for (let i = 0; i < rawLegendStops.length; i++) {
    let { label } = rawLegendStops[i];
    if (label instanceof Array) {
      label = [...label].reverse();
      if (label.length > 1) {
        any_tall_legends = true;
      }
      label = (
        <>
          { label.map((el, j) => {
            return (
              <span
                className={j > 0 ? styles.map_legend_upper_label : styles.map_legend_lower_label}
                key={`${i}:${el}`}
              >
                {el}
              </span>
            );
          }) }
        </>
      );
    }
    legend_stops.push({
      value: rawLegendStops[i].value,
      label
    });
  }

  const sx = { ...legendSx };
  sx['.MuiSlider-rail'].background = legendBackground;

  return (
    <div className={styles.map_legend}>
      { setting.width === 'count_absolute'
        && (
          <div className={styles.volume_thickness_container}>
            <div className={styles.volume_thickness_labels}>
              <span>Low volume</span>
              <span>High volume</span>
            </div>
            <div className={styles.volume_thickness_lines}>
              <span />
              <span />
              <span />
            </div>
          </div>
        )}
      <Slider
        valueLabelDisplay="off"
        min={min_val}
        max={max_val}
        color="secondary"
        marks={legend_stops}
        sx={legendSx}
        className={any_tall_legends ? styles.legend_slider : styles.legend_slider_short}
      />
      {setting.legend_title}
    </div>
  );
}
