import React, { MouseEventHandler, ReactChild } from 'react';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import styles from './ToggleGroup.module.css';

function ToggleButton(
  { children, value, isCurrent, handleClick, tooltip }:
    { children?:ReactChild, value: string, isCurrent: boolean, handleClick: any, tooltip?: string }
) {
  const internal = (
    <Button
      size="small"
      onClick={isCurrent ? null : handleClick}
      value={value}
      key={value}
      variant={isCurrent ? 'contained' : undefined}
      className={isCurrent ? styles.switch_button_selected : styles.switch_button}
    >
      {children}
    </Button>
  );
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="left">
        {internal}
      </Tooltip>
    );
  }
  return internal;
}

export function ToggleGroup(
  { handleClick, value, values, label_map, tooltips, horizontal = false, className }:
  {
    handleClick: MouseEventHandler,
    value: string,
    values: Array<string>,
    label_map: { [index: string]: string },
    tooltips?: { [index: string]: string },
    horizontal?: boolean
    className?: string
  }
) {
  let classNames = `${styles.switch_container}`;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <ButtonGroup
      fullWidth
      disableElevation
      orientation={horizontal ? 'horizontal' : 'vertical'}
      aria-label="outlined secondary button group"
      className={classNames}
    >
      { values.map((item_value) => {
        return (
          <ToggleButton
            value={item_value}
            isCurrent={item_value === value}
            handleClick={handleClick}
            tooltip={tooltips && tooltips[item_value]}
            key={item_value}
          >
            {label_map[item_value]}
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
}
