import { TravelTimeRouteData, XyArray } from '../../appTypes';
import { ChartDataExpander, sparseToDense } from './ChartDataExpander';

export class TravelTimeChartDataExpander extends ChartDataExpander {
  expandTravelTimes(rawTTs: TravelTimeRouteData): XyArray {
    // extract and backfill sparse TT Data array with null datapoints for all time bins
    // Infer bin size from first row bin size
    if (rawTTs) {
      console.assert(rawTTs.bin_size !== undefined, rawTTs);
      this.binTicksMs = rawTTs.bin_size * 60 * 1000;
    } else {
      return undefined;
    }
    if (rawTTs?.times) {
      return sparseToDense(rawTTs.times, this.firstBin, this.lastBin, this.binTicksMs, this.offsetTo);
    }
    return undefined;
  }
}
