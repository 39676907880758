// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';

import { restApiUrl } from 'appConstants';

import {
  RealtimeData, VNRTData,
} from 'appTypes';
import { getMinuteTicker } from '../features/workflow_timeline/RealtimeDataProvider';

function urlifySlots(slots) {
  if (slots) {
    return slots.join(',');
  } else {
    return '';
  }
}

// Define our single API slice object
export const realtimeApiSlice = createApi({
  reducerPath: 'realtime_api',
  baseQuery: fetchBaseQuery({ baseUrl: restApiUrl }),
  // The "endpoints" represent operations and requests for this server
  endpoints: (builder) => ({
    getRealtimeData: builder.query<RealtimeData, { project_slug: string, date: string }>({
      query: ({ project_slug, date }) => {
        const now = getMinuteTicker().format('HH:mm:ss'); // This effectively sets the polling frequency to once every 60 seconds
        const queryString = `${project_slug}/realtime/now/${date}/${now}/`;
        // console.log(`getSegments queryString: ${queryString}`);
        return queryString;
      },
      transformResponse: (
        rawResult: any,
        meta,
        arg
      ) => {
        console.log('getRealtimeData load');
        return {
          data: rawResult.data,
          timestamp: rawResult.timestamp,
        };
      },
    }),
    getVNRTData: builder.query<VNRTData, { project_slug: string, date: string, slots: Array<number> }>({
      query: ({ project_slug, date, slots }) => {
        console.log('Fetching vnrt slots', slots);
        const queryString = `${project_slug}/realtime/table/${date}/?slots=${urlifySlots(slots)}`;
        // console.log(`getSegments queryString: ${queryString}`);
        return queryString;
      },
      transformResponse: (
        rawResult: any,
        meta,
        arg
      ) => {
        console.log('getVNRTData load');
        return {
          data: rawResult.data,
          columns: rawResult.columns,
          last_observation: rawResult.last_observation,
        };
      },
    }),
  }),
});

const initialState: {
  representedSlots: { [index: number]:boolean },
  vnrtData: { [index: string]:{ s: string, o: number, v: number }}
} = {
  representedSlots: {},
  vnrtData: {}
};

export function vnrt_table_to_slots(table) {
  const new_slots = {};
  for (let i = 0; i < table.length; i++) {
    const r = table[i];
    const row = { s: r[0], o: r[1], v: r[2] };
    if (!new_slots[row.o]) new_slots[row.o] = [];
    new_slots[row.o].push(row);
  }
  return new_slots;
}

export const realtimeSlice = createSlice({
  name: 'realtime',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRepresentedSlots: (state, action) => {
      // console.log('Setting displayed bookmark to ', action.payload);
      state.representedSlots = action.payload;
    },
    addToVNRTData: (state, action) => {
      // console.log('Setting displayed bookmark to ', action.payload);
      const new_slots = vnrt_table_to_slots(action.payload);
      state.vnrtData = { ...state.vnrtData, ...new_slots };
    },
    replaceVNRTData: (state, action) => {
      state.vnrtData = vnrt_table_to_slots(action.payload);
    }
  }
});

export function getRealtimeState(rootState) {
  return rootState[realtimeSlice.name];
}

export function selectRepresentedSlots(state) {
  return getRealtimeState(state).representedSlots;
}

export function selectVnrtData(state) {
  return getRealtimeState(state).vnrtData;
}

const { actions, reducer } = realtimeSlice;
export const {
  setRepresentedSlots,
  addToVNRTData,
  replaceVNRTData
} = actions;

export const {
  useGetRealtimeDataQuery,
  useGetVNRTDataQuery
} = realtimeApiSlice;
