import React, { PropsWithChildren } from 'react';
import InputLabel from '@mui/material/InputLabel';

import styles from './Taskbar.module.css';

export function OverflowLabel({ children, id }: PropsWithChildren & { id?: string }) {
  return (<InputLabel className={styles.overflowLabel} id={id}>{children}</InputLabel>);
}

export function SplitOverflowLabel(
  { children, id, label }: PropsWithChildren & { id?: string, label: React.ReactNode }
) {
  return (
    <InputLabel className={styles.overflowLabel} id={id}>
      {label}
      &nbsp;
      {children}
    </InputLabel>
  );
}
