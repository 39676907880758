import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import { AnyAction } from 'redux';
import userReducer from 'state/userSlice';
import workflowReducer from 'state/workflowSlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { apiSlice } from 'state/apiSlice';
import { bookmarkApiSlice, bookmarkSlice } from './bookmarkSlices';
import { realtimeApiSlice, realtimeSlice } from './realtimeSlices';

// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['workflow'],
};

const rootReducer = combineReducers({
  user: userReducer,
  workflow: workflowReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [bookmarkApiSlice.reducerPath]: bookmarkApiSlice.reducer,
  [bookmarkSlice.name]: bookmarkSlice.reducer,
  [realtimeApiSlice.reducerPath]: realtimeApiSlice.reducer,
  [realtimeSlice.name]: realtimeSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const trueRootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return persistedReducer(state, action);
};

//  @ts-ignore
export const store = configureStore({
  reducer: trueRootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      warnAfter: 256,
      ignoredActions: ['persist/PERSIST'],
    },
    immutableCheck: { warnAfter: 128 },
  }).concat(apiSlice.middleware)
    .concat(bookmarkApiSlice.middleware)
    .concat(realtimeApiSlice.middleware) as any,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export function getState(): RootState {
  return store.getState() as any as RootState;
}
