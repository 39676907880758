import React, { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import dayjs from 'dayjs';

import { useSelector, useDispatch } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

import {
  chart_background_color, null_grey,
  stl_light_background_color,
  stl_light_grey, stl_realtime_color, stl_realtime_color_light, stl_realtime_color_light_desat,
  transparent_null_grey, workspace_color
} from 'theme/cemTheme';
import styles from 'features/workflow_timeline/WorkflowTimeline.module.css';

import {
  selectShortestAvailableBin, selectTargetDate, selectTimelineMetric,
  selectTimeOfDay, setTimelineMetric,
  setTimeOfDay
} from '../../state/workflowSlice';
import { useActivityTimeseries } from './activityTimeseries';
import { ColorGradient, bg_yellow_red, grey_yellow_red, timeline_bar_gradient } from '../chart/colorMaps';
import {
  isRealtimeMetric, isVNRTMetric,
  TL_METRICS_OPTIONS_RT_EQUIVALENT,
  TL_METRICS_OPTIONS_VNRT,
  TL_METRICS_OPTIONS_VNRT_EQUIVALENT
} from './metricsOptions';
import { getMinuteTicker, useRealtimeData } from './RealtimeDataProvider';
import { selectRepresentedSlots, selectVnrtData } from '../../state/realtimeSlices';
import { useCallbackWithErrorHandling } from '../../app/ErrorHandling';
import { formatTimeOfDay, minutes_to_slots, slots_to_minutes, ts_to_minutes } from './slotsUtils';
import { selectUserState } from '../../state/userSlice';

const timeMin = -3 * 60;
const timeMax = (24 + 3) * 60;

const timeSliderTheme = createTheme({
  components:
    {
      MuiSlider: {
        styleOverrides: {
          root: {
            disableRipple: true
          },
          thumb: {
            borderRadius: '12px',
            height: '25px',
            width: '40px',
            background: 'none',
            boxShadow: '0px 0px 15px 0px #00000080 !important',
            ':hover': {
              boxShadow: '0px 0px 15px 0px #00000080',
            },
            '::after': {
              border: '3px white solid',
              borderRadius: '12px',
              zIndex: 100,
              height: '25px',
              width: '40px',
              background: 'none',
            }
          },
          rail: {
            height: '8px',
            borderRadius: '4px',
            backgroundColor: stl_light_background_color,
            opacity: 0.8
          },
          track: {
            height: '30px',
            background: 'none',
            border: 'none',
          },
          mark: {
            height: '25px',
            borderRadius: '1px',
            backgroundColor: 'var(--primary-text)',
            opacity: 0.7,
            border: '1px solid black'
          },
          markActive: {
            backgroundColor: stl_light_grey
          },
          markLabel: {
            color: 'var(--primary-text)'
          },
          valueLabel: {
            marginTop: '1px',
            background: `linear-gradient(to left, ${workspace_color}00, ${workspace_color}, ${workspace_color}, ${workspace_color}00)`,
            width: '20em',
            color: 'var(--primary-text)',
            transform: 'translateY(33px) !important'
          },
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            color: 'var(--primary-text)',
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            alignItems: 'start !important'
          },
          item: {
            paddingTop: '0',
          }
        }
      },
    }
});

export function TimeSlider() {
  const timeOfDay = useSelector(selectTimeOfDay);
  const binSize = useSelector(selectShortestAvailableBin);
  const binCount = binSize ? (((3 + 24 + 3) * 60) / binSize) : 1;
  const timelineMetric = useSelector(selectTimelineMetric);
  const targetDate = useSelector(selectTargetDate);
  const slotsRepresented = useSelector(selectRepresentedSlots);
  const vnrtData = useSelector(selectVnrtData);
  const dispatch = useDispatch();
  const [playing, setPlaying] = useState(false);
  const refPlayingCallback = useRef(undefined);
  const refTimeOfDay = useRef(timeOfDay);
  const userInfo = useSelector(selectUserState);

  refTimeOfDay.current = timeOfDay;

  const tt = getMinuteTicker();
  const { realtime: realtimeData, is_complete_vnrt } = useRealtimeData(tt);

  const maxRepresentedBin = useMemo(() => Math.max(...(Object.keys(slotsRepresented)).map(Number)), [slotsRepresented]);

  const { timeseries } = useActivityTimeseries();
  const colorMap = new ColorGradient(timeline_bar_gradient(0, 100).toInterleaved());
  const railBackgroundMemo = useMemo(() => {
    let railBackground;
    if (timeseries && !TL_METRICS_OPTIONS_VNRT.includes(timelineMetric)) {
      railBackground = 'linear-gradient(0.25turn';
      for (let i = 0; i < timeseries.length; i++) {
        const value = timeseries[i];
        console.assert((value <= 1) || Number.isNaN(value), value, timeseries, i);
        let color;
        if (!Number.isNaN(value)) {
          color = colorMap.sample(value * 100);
        } else {
          color = null_grey;
        }
        railBackground += `, ${color} ${(100 * (i / timeseries.length)).toFixed(3)}%`;
      }
      railBackground += ');';
    } else if (is_complete_vnrt && Object.keys(slotsRepresented).length === 0) {
      railBackground = transparent_null_grey;
    } else if (Object.keys(slotsRepresented).length > 0) {
      railBackground = 'linear-gradient(0.25turn';
      let state;
      for (let i = 0; i < binCount; i++) {
        let value = slotsRepresented[i.toString()] ? 1 : 0;
        value = value || vnrtData[i.toString()] ? 1 : 0;
        if (value !== state) {
          const offset = (100 * (Math.max(i - 0.5, 0) / binCount));
          if (state !== undefined) {
            const color = state ? stl_realtime_color_light_desat : transparent_null_grey;
            railBackground += `, ${color} ${(offset - 0.1).toFixed(3)}%`;
          }
          const color = value ? stl_realtime_color_light_desat : transparent_null_grey;
          railBackground += `, ${color} ${offset.toFixed(3)}%`;
        }
        state = value;
      }
      railBackground += `, ${state ? stl_realtime_color_light_desat : transparent_null_grey} 100%`;
      railBackground += ');';
    }

    return railBackground;
  }, [timeseries, binSize, slotsRepresented, targetDate, is_complete_vnrt]);

  function time_of_day_surpases_rt(value) {
    if (realtimeData) {
      const realtimeMinOffset = ts_to_minutes(realtimeData.timestamp, targetDate);
      if (value >= realtimeMinOffset) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  const constrainSliderToRealtime = () => {
    if (realtimeData) {
      const offset = ts_to_minutes(realtimeData.timestamp, targetDate);
      // console.log('Setting time of day to ', offset, t);
      if (offset <= timeMax) {
        dispatch(setTimeOfDay(offset));
      } else {
        dispatch(setTimelineMetric(TL_METRICS_OPTIONS_VNRT_EQUIVALENT[timelineMetric]));
      }
    }
  };

  function constrainSliderToBins(newValue) {
    if (binSize) {
      const newToD = binSize * Math.floor(newValue / binSize); // Round to bin
      if (newToD !== timeOfDay) {
        if (newToD !== newValue) {
          console.log('Constraining time of day from a -> b', timeOfDay, newToD);
        }
        dispatch(setTimeOfDay(newToD));
      }
    }
  }

  function constrainSliderToAppropriate() {
    if (isRealtimeMetric(timelineMetric)) {
      constrainSliderToRealtime();
    } else if (isVNRTMetric(timelineMetric)) {
      let newTimeOfDay = timeOfDay;
      if (!is_complete_vnrt && realtimeData) { // If this is a day that supports Realtime, but we're in a VNRT metric
        if (time_of_day_surpases_rt(timeOfDay)) { // If the time slider is set above RT
          newTimeOfDay = ts_to_minutes(realtimeData.timestamp, targetDate) - 15; // Set the time slider to be at the RT position
        }
      }
      constrainSliderToBins(newTimeOfDay);
    }
  }

  const handleSliderChange = (event: Event, newValue: number) => {
    console.assert(newValue >= timeMin && newValue <= timeMax);
    dispatch(setTimeOfDay(newValue));
    if (TL_METRICS_OPTIONS_VNRT.includes(timelineMetric)) {
      if (is_complete_vnrt) {
        dispatch(setTimelineMetric(TL_METRICS_OPTIONS_VNRT_EQUIVALENT[timelineMetric] || timelineMetric));
        constrainSliderToBins(newValue);
      } else {
        const hasVnrtData = Object.keys(vnrtData).length > 0;
        if (hasVnrtData || realtimeData) {
          if (time_of_day_surpases_rt(newValue)) {
            if (isVNRTMetric(timelineMetric)) {
              dispatch(setTimelineMetric(TL_METRICS_OPTIONS_RT_EQUIVALENT[timelineMetric]));
            }
            constrainSliderToRealtime();
          } else if (isRealtimeMetric(timelineMetric)) {
            dispatch(setTimelineMetric(TL_METRICS_OPTIONS_VNRT_EQUIVALENT[timelineMetric]));
            constrainSliderToBins(newValue);
          } else {
            constrainSliderToBins(newValue);
          }
        }
      }
    }
    // TODO Space these so only once every 200ms
  };

  useEffect(() => {
    // This handles date change, either from a user changing date or by hitting RT, thus it has to not force RT -> VNRT
    if (TL_METRICS_OPTIONS_VNRT.includes(timelineMetric)) {
      if (is_complete_vnrt) { // Switch to vnrt from rt if needs be
        dispatch(setTimelineMetric(TL_METRICS_OPTIONS_VNRT_EQUIVALENT[timelineMetric] || timelineMetric));
        constrainSliderToBins(timeOfDay);
      } else { // Otherwise lets not change metric, just fix up the ol' time slider
        constrainSliderToAppropriate();
      }
    }
  }, [targetDate, is_complete_vnrt]);

  useEffect(() => {
    constrainSliderToAppropriate();
  }, [realtimeData, vnrtData]);

  useEffect(() => {
    constrainSliderToAppropriate();
  }, [timelineMetric]);

  useEffect(() => {
    if (slotsRepresented && Object.keys(slotsRepresented).length > 0) {
      if (minutes_to_slots(timeOfDay, binSize) > maxRepresentedBin) {
        dispatch(setTimeOfDay(slots_to_minutes(maxRepresentedBin, binSize)));
      }
    }
  }, [slotsRepresented]);

  function playingCallback() {
    if (refTimeOfDay.current < timeMax) {
      handleSliderChange({} as any, Math.min(refTimeOfDay.current + binSize, timeMax));
      if (playing) {
        refPlayingCallback.current = setTimeout(playingCallback, 300);
      }
    } else {
      setPlaying(false);
    }
  }

  useEffect(() => {
    if (playing) {
      if (!refPlayingCallback.current) {
        playingCallback();
      }
    } else if (refPlayingCallback.current) {
      clearTimeout(refPlayingCallback.current);
      refPlayingCallback.current = undefined;
    }
  }, [playing]);

  const marks = [
    {
      value: 0,
      label: '00:00'
    },
    {
      value: 12 * 60,
      label: '12:00'
    },
    {
      value: 24 * 60,
      label: '00:00'
    }
  ];
  let railBackground;
  if (!railBackgroundMemo) {
    railBackground = `${chart_background_color} !important`;
  } else {
    railBackground = railBackgroundMemo;
  }

  const isRealtime = isRealtimeMetric(timelineMetric);

  return (
    <ThemeProvider theme={timeSliderTheme}>
      <Box>
        <Grid container spacing={0} alignItems="center">
          <Grid item>
            <Tooltip title="Go back a time step">
              <IconButton
                size="small"
                onClick={(event) => handleSliderChange(event as any, Math.max(timeOfDay - binSize, timeMin))}
                disabled={timeOfDay === timeMin}
                className={styles.dayChangeButton}
              >
                <ArrowLeftIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Slider
              value={timeOfDay}
              onChange={useCallbackWithErrorHandling(handleSliderChange)}
              aria-labelledby="threshold-slider"
              getAriaValueText={formatTimeOfDay}
              valueLabelFormat={formatTimeOfDay}
              className={isRealtime ? styles.realtime_slider_thumb : undefined}
              valueLabelDisplay="on"
              min={timeMin}
              max={timeMax}
              step={binSize}
              color="secondary"
              marks={marks}
              sx={{
                '.MuiSlider-rail': {
                  background: railBackground,
                },
                '.MuiSlider-thumb': {
                  background: isRealtime ? stl_realtime_color : undefined,
                  borderColor: isRealtime ? stl_realtime_color : undefined,
                  // animation: isRealtime ? `${styles.realtime_thumb_pulse} 2s infinite !important` : undefined,
                },
                '.MuiSlider-thumb::after': {
                  border: isRealtime ? `3px ${stl_realtime_color} solid` : '3px white solid',
                  animation: isRealtime ? `${styles.realtime_thumb_pulse} 2s infinite` : undefined,
                }
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Go forward a time step">
              <IconButton
                size="small"
                onClick={(event) => handleSliderChange(event as any, Math.min(timeOfDay + binSize, timeMax))}
                disabled={timeOfDay === timeMax}
                className={styles.dayChangeButton}
              >
                <ArrowRightIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          { userInfo?.has_hidden_features
          && (
            <Grid item>
              <Tooltip title="Play">
                <IconButton
                  size="small"
                  onClick={(event) => setPlaying(!playing)}
                  disabled={isRealtimeMetric(timelineMetric)}
                  color={playing ? 'warning' : undefined}
                  className={playing ? styles.playButtonPlaying : styles.dayChangeButton}
                >
                  {!playing ? <PlayCircleOutline /> : <PauseCircleOutline />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
