import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import { LayerContext } from 'state/LayerContext';

import { selectSegmentId, selectTargetDate } from 'state/workflowSlice';
import { useGetSegmentInfoQuery } from 'state/apiSlice';
import { displayFriendlyDate } from 'appUtils';

import { MaximizeButton } from 'features/workspace/MaximizeButton';

import styles from 'features/workspace/Workspace.module.css';

export function SegmentInfo({ graph_title }: { graph_title: string }) {
  const targetDate = useSelector(selectTargetDate);
  const segmentId = useSelector(selectSegmentId);

  // cached so no need to memoize
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId }
  );

  const description = useMemo(
    () => {
      const segmentProperties = segmentData && segmentData?.segments[segmentId]?.properties;
      if (segmentProperties && targetDate) {
        return `${graph_title} | ${segmentProperties.name} ${segmentProperties.cardinal_dir} | ${displayFriendlyDate(targetDate)}`;
      }
      return null;
    },
    [segmentData, segmentId, targetDate]
  );

  return (
    <div>
      {description}
    </div>
  );
}

export function ChartHeader({ graph_title } : { graph_title: string }) {
  return (

    <div className={styles.chart_header}>
      <div className={styles.chart_header_left}>
        <SegmentInfo
          graph_title={graph_title}
        />
      </div>

      <div className={styles.chart_header_right}>
        <MaximizeButton />
      </div>
    </div>

  );
}
