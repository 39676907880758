import { createContext } from 'react';

// https://react.dev/reference/react/createContext
// note: this initial value is never used - a new value is set by LayerContext.Provider
// The value that you want the context to have when there is no matching context provider
// in the tree above the component that reads context. If you don’t have any meaningful default value, specify null.
// The default value is meant as a “last resort” fallback. It is static and never changes over time.
export const LayerContext = createContext({
  layer: null,
  setLayer: (_layer: number) => { },
});
