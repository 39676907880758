import React from 'react';

import Button from '@mui/material/Button';
import { Download } from '@mui/icons-material';

import styles from 'features/task_bar/Taskbar.module.css';

// https://www.highcharts.com/docs/export-module/client-side-export
// https://www.material-react-table.com/docs/examples/data-export

export function ExportButton(
  { value, isActive, handleClick }:
    { value?: string, isActive: boolean, handleClick: any }
) {
  return (

    <Button
      variant="contained"
      onClick={isActive ? handleClick : null}
      className={styles.export_button}
    >
      <Download />
      <span>{value || 'Export'}</span>
    </Button>

  );
}
