import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTimelineMetric,
  selectTimelineMetric,
  selectCurrentProjectInfo,
  selectTargetDate,
  selectProject, setTargetDate
} from 'state/workflowSlice';

import styles from 'features/task_bar/Taskbar.module.css';
import {
  TL_METRICS_OPTIONS_NRT,
  TL_METRICS_OPTIONS_VNRT,
  TL_METRIC_TYP_REL_SPEED_VOL,
  TL_METRIC_TYP_REL_VOL,
  TL_METRIC_TYP_VHD,
  TL_METRIC_FF_REL_SPEED_VOL,
  TL_METRIC_TYP_RT,
  TL_METRIC_TYP_VNRT,
  TL_METRIC_FF_RT,
  TL_METRIC_FF_VNRT,
  isRealtimeMetric,
  TL_METRICS_OPTIONS_VNRT_EQUIVALENT
} from 'features/workflow_timeline/metricsOptions';
import { ToggleGroup } from 'features/common/ToggleGroup';
import { OverflowLabel } from 'features/task_bar/OverflowLabel';

import { ComparisonPicker } from '../task_bar/ComparisonPicker';
import { findRTDate, useDayInfo } from '../task_bar/DatePicker';
import { useGetDatesQuery } from '../../state/apiSlice';

const METRIC_FF = 'ff';
const METRIC_TYP = 'typ';

export function TimelineMetricsOption() {
  const project = useSelector(selectProject);
  const userProject = useSelector(selectCurrentProjectInfo);
  const timelineMetric = useSelector(selectTimelineMetric);
  const dispatch = useDispatch();
  const [rtMetric, setRtMetric] = useState(METRIC_TYP);

  const { data: datesInfo } = useGetDatesQuery(project, { skip: !project });
  const { day_info } = useDayInfo();

  const [isRealtime, setIsRealtime] = useState(!(day_info?.is_complete_vnrt));

  const metricNames = {
    [TL_METRIC_TYP_REL_SPEED_VOL]: 'Atypical Speeds',
    [TL_METRIC_FF_REL_SPEED_VOL]: 'Speeds',
    [TL_METRIC_TYP_REL_VOL]: 'Atypical Volumes',
    [TL_METRIC_TYP_VHD]: 'Atypical Delay',
    [TL_METRIC_TYP_RT]: 'Atypical Realtime',
    [TL_METRIC_FF_RT]: 'Freeflow Realtime',
    [TL_METRIC_TYP_VNRT]: 'Atypical VNRT',
    [TL_METRIC_FF_VNRT]: 'Freeflow VNRT',
    [METRIC_TYP]: 'Atypical Slowdowns',
    [METRIC_FF]: 'Slowdowns'
  };

  let availableMetrics;
  let safeDefault;
  let is_nrt = true;
  if (!day_info || day_info.is_nrt) {
    availableMetrics = TL_METRICS_OPTIONS_NRT;
    // eslint-disable-next-line prefer-destructuring
    safeDefault = availableMetrics[0];
  } else {
    is_nrt = false;
    availableMetrics = TL_METRICS_OPTIONS_VNRT;
    safeDefault = day_info.is_complete_vnrt ? TL_METRIC_TYP_VNRT : TL_METRIC_TYP_RT;
  }

  const tooltips = {
    [TL_METRIC_TYP_REL_VOL]: 'Observed vehicle activity compared to typical',
    [TL_METRIC_FF_REL_SPEED_VOL]: 'Speed compared to free-flow, widths indicate relative volume',
    [TL_METRIC_TYP_REL_SPEED_VOL]: 'Speed compared to typical, widths indicate relative volume',
    [TL_METRIC_TYP_VHD]: 'Vehicle hours of delay compared to typical',
    [TL_METRIC_TYP_RT]: 'Atypical Realtime',
    [TL_METRIC_FF_RT]: 'Realtime compared to freeflow',
    [TL_METRIC_TYP_VNRT]: 'Atypical Recent',
    [TL_METRIC_FF_VNRT]: 'Recent compared to freeflow',
    [METRIC_TYP]: 'Atypical Slowdowns',
    [METRIC_FF]: 'Slowdowns'
  };

  const setMetric = (value) => {
    console.log(`TimelineMetricsOption handleChange newTimelineMetric: ${value}`);
    if (day_info && !day_info.is_nrt && isRealtimeMetric(value) && day_info.is_complete_vnrt) {
      // Are we on a day where there is no realtime?, but trying to set a realtime metric?
      const newDate = findRTDate(datesInfo);
      if (newDate) {
        dispatch(setTargetDate(newDate.date));
      } else {
        value = TL_METRICS_OPTIONS_VNRT_EQUIVALENT[value];
      }
    }
    dispatch(setTimelineMetric(value));
  };

  function rtMetricLookup(irtMetric: string, iisRealtime: boolean) {
    if (iisRealtime) {
      return { [METRIC_TYP]: TL_METRIC_TYP_RT, [METRIC_FF]: TL_METRIC_FF_RT }[irtMetric];
    } else {
      return { [METRIC_TYP]: TL_METRIC_TYP_VNRT, [METRIC_FF]: TL_METRIC_FF_VNRT }[irtMetric];
    }
  }

  function rtReverseLookup(iMetric: string) {
    return {
      [TL_METRIC_TYP_RT]: [METRIC_TYP, true],
      [TL_METRIC_FF_RT]: [METRIC_FF, true],
      [TL_METRIC_TYP_VNRT]: [METRIC_TYP, false],
      [TL_METRIC_FF_VNRT]: [METRIC_FF, false],
    }[iMetric];
  }

  const handleMetricToggleClick = (event) => {
    const newMetricToggleValue = event.target.value;
    setMetric(newMetricToggleValue);
  };

  const handleRtMetricToggleClick = (event) => {
    const newMetricToggleValue = event.target.value;
    setRtMetric(newMetricToggleValue);
    const newMetric = rtMetricLookup(newMetricToggleValue, isRealtime);
    setMetric(newMetric);
  };

  const handleRtNRTToggleClick = (event) => {
    const newIsRealtime = event.target.value === 'true';
    setIsRealtime(newIsRealtime);
    const newMetric = rtMetricLookup(rtMetric, newIsRealtime);
    setMetric(newMetric);
  };

  useEffect(() => {
    if (!availableMetrics.includes(timelineMetric) && day_info) {
      dispatch(setTimelineMetric(safeDefault));
    }
    if (!is_nrt && TL_METRICS_OPTIONS_VNRT.includes(timelineMetric)) {
      const [newRtMetric, newIsRealtime] = rtReverseLookup(timelineMetric);
      setRtMetric(newRtMetric as string);
      setIsRealtime(newIsRealtime as boolean);
    }
  }, [dispatch, timelineMetric, userProject, day_info, safeDefault]);

  let mainMetricSelector;
  if (is_nrt) {
    mainMetricSelector = (
      <div>
        <OverflowLabel id="timeline-metric-picker-label">
          Metric:
          <ToggleGroup
            value={timelineMetric}
            values={availableMetrics}
            tooltips={tooltips}
            label_map={metricNames}
            handleClick={handleMetricToggleClick}
          />
        </OverflowLabel>
      </div>
    );
  } else {
    mainMetricSelector = (
      <div>
        <OverflowLabel id="timeline-metric-picker-label">
          Metric:
          <ToggleGroup
            value={rtMetric}
            values={[METRIC_TYP, METRIC_FF]}
            tooltips={tooltips}
            label_map={metricNames}
            handleClick={handleRtMetricToggleClick}
          />
          <ToggleGroup
            className={styles.metrics_space_above}
            handleClick={handleRtNRTToggleClick}
            value={String(isRealtime)}
            values={['true', 'false']}
            label_map={{ true: 'Realtime', false: 'Recent' }}
            horizontal
          />
        </OverflowLabel>
      </div>
    );
  }

  const typicalMonthSelector = (
    <ComparisonPicker />
  );

  return (
    <div className={styles.task_bar_component}>
      {mainMetricSelector}
      {typicalMonthSelector}
    </div>
  );
}
