import { primary_text_color, chart_background_color } from '../../theme/cemTheme';

export const background_color = chart_background_color;
export const text_color = primary_text_color;
export const label_color = text_color;
export const axis_color = label_color;
export const grid_color = '#888888';
export const day_grid_color = '#cbcbcb';
export const font_size = '1em';
export const freeflow_color = '#87d74f';
export const recent_speed_color = '#fedb75';
export const typical_color = '#77b1d9'; // blue
export const graph_highlight_color = 'rgba(255,218,106,.5)';

export function getBandMembership(x, bands) {
  if (!bands) {
    return undefined;
  }
  for (let i = 0; i < bands.length; i++) {
    const band = bands[i];
    if (x >= band.from && x <= band.to) {
      return band;
    }
  }
  return undefined;
}

export const baseChartOptions = {
  chart: {
    backgroundColor: background_color,
    plotBackgroundColor: background_color,
    animation: false,
    marginRight: '150',
  },
  accessibility: {
    // disabled (for now?) because it adds markers on all points on update
    // https://github.com/highcharts/highcharts/issues/15480
    enabled: false,
  },
  animation: false,
  credits: {
    enabled: false
  },
  title: {
    text: '',
  },
  tooltip: {
    shared: true,
    padding: 0,
    useHTML: true,
    backgroundColor: '#0000',
    borderColor: '#0000',
    shadow: false
  },
  plotOptions: {
    series: {
      animation: false,
      marker: {
        enabled: false,
      },
      line: {
        states: {
          hover: {
            enabled: false,
          }
        }
      }
    },
  },
  time: {
    useUTC: false,
    // timezone appears to have no effect here
  },
  legend: {
    reversed: true,
    verticalAlign: 'middle',
    align: 'right',
    layout: 'vertical',
    itemWidth: 120,
    x: 10,
    itemStyle: {
      color: primary_text_color, // segment speed label
      fontSize: '1em',
      fontWeight: 'normal',
    },
    itemHoverStyle: {
      color: primary_text_color, // segment speed label
      fontSize: '1em',
      fontWeight: 'bold',
    }
  },
};
