import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectBinSize,
  selectProject,
  selectRouteSelection,
  selectSegmentId,
  selectTypicalMonth
} from '../../state/workflowSlice';
import {
  useGetPlanningVolumesQuery
} from '../../state/apiSlice';

export function usePlanningVolumesDataProvider() {
  const project_slug = useSelector(selectProject);
  const typicalMonth = useSelector(selectTypicalMonth);
  const segmentId = useSelector(selectSegmentId);
  const routeSelection = useSelector(selectRouteSelection);
  const binSize = useSelector(selectBinSize);

  // typicalData from REST api
  const { currentData: planningVolumeData } = useGetPlanningVolumesQuery(
    { project_slug, typicalMonth, segmentId, binSize },
    { skip: !(typicalMonth && segmentId) || (typicalMonth && routeSelection) }
  ) as any;

  return planningVolumeData;
}
