import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import { Link } from '@mui/material';

import { logoutUser, resetUser, UserState } from 'state/userSlice';
import { resetWorkflowState } from 'state/workflowSlice';

import styles from './Logout.module.css';

export function Logout({ children: user }: { children: UserState }) {
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(resetUser());
    dispatch(resetWorkflowState());

    // @ts-ignore
    // ! TS2345: Argument of type 'AsyncThunkAction<any, void, AsyncThunkConfig>' is not assignable to parameter of type 'AnyAction'.
    dispatch(logoutUser());
  }, [dispatch]);

  return (
    <div className={styles.nav_list}>
      <div>{user.username}</div>
      <div>
        { user?.has_hidden_features
          && (
            <Link
              className={styles.hidden_features}
              href="/admin"
            >
              _
            </Link>
          )}
        <Button
          className={styles.logout_link}
          onClick={logOut}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}

// // https://blog.logrocket.com/validate-react-props-proptypes/
// Logout.propTypes = {
//   children: PropTypes.object,
// };
