import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  selectTargetDate,
  selectTypicalMonth,
  selectSegmentId,
  selectMaximize,
  selectCurrentProjectInfo, selectComparison
} from 'state/workflowSlice';
import { selectSlowdownThreshold } from 'state/workflowSlice';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetSegmentInfoQuery } from 'state/apiSlice';

import { LayerContext } from 'state/LayerContext';

import { speedChartOptions } from 'features/chart/speedChartOptions';
import { ChartDataExpander } from 'features/chart/ChartDataExpander';
import { convertSpeeds, formatSpeed, speedUnit } from 'features/common/utils';

import 'features/chart/Chart.css';
import styles from 'features/chart/Chart.module.css';
import { useSpeedsDataProvider } from '../workflow_nrt/nrtSpeedsCommon';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';
import { dayMarkerLineBase, formatTargetSeriesName, formatTypicalSeriesName, makeFormatTooltip } from './nrtChartCommon';

export function SpeedChart() {
  const targetDate: string = useSelector(selectTargetDate);
  const segmentId: string = useSelector(selectSegmentId);
  const slowdownThreshold = useSelector(selectSlowdownThreshold);
  const typicalMonth = useSelector(selectTypicalMonth);
  const userProject = useSelector(selectCurrentProjectInfo);
  const comparison = useSelector(selectComparison);

  const chartComponent = useRef(null); // so we can call reflow

  const [chartOptions, setChartOptions] = useState(speedChartOptions as any);
  const maximize = useSelector(selectMaximize);

  // speedData from REST api
  const { speedData, comparisonSpeedData } = useSpeedsDataProvider();

  // segmentData layer from REST api
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId }
  );
  // memoize chartDataExpander
  const chartDataExpander = useMemo(
    () => (targetDate && new ChartDataExpander(targetDate)),
    [targetDate]
  );

  // memoize segmentProperties
  const segmentProperties = useMemo(
    () => segmentData && segmentData?.segments[segmentId]?.properties,
    [segmentData, segmentId]
  );

  // Hold ref to old data so we can keep it alive while the new graph is loading
  const segmentSpeedsRef = useRef(undefined);
  // memoize expanded segmentSpeeds
  const segmentSpeeds = useMemo(
    () => {
      const value = chartDataExpander?.expandSpeeds(speedData, userProject.uses_metric);
      if (value) {
        segmentSpeedsRef.current = { value, date: targetDate, seg: segmentId };
        return value;
      } else if (segmentSpeedsRef.current?.date === targetDate && segmentSpeedsRef.current?.seg === segmentId) {
        return segmentSpeedsRef.current.value;
      }
      return undefined;
    },
    [speedData, chartDataExpander, targetDate, userProject]
  );
  // console.log(`memoized segmentSpeeds ${JSON.stringify(segmentSpeeds)}`);

  const comparisonSpeedsRef = useRef(undefined);
  // memoize comparisonSpeeds
  const comparisonSpeeds = useMemo(
    () => {
      if (comparison) {
        const relavantDate = isComparisonMonth(comparison) ? targetDate : comparison;
        const comparisonChartDataExpander = new ChartDataExpander(relavantDate, targetDate);
        const value = comparisonChartDataExpander.expandSpeeds(comparisonSpeedData, userProject.uses_metric);
        if (value) {
          comparisonSpeedsRef.current = { value, date: targetDate, seg: segmentId };
          return value;
        } else if (comparisonSpeedsRef.current?.date === targetDate && comparisonSpeedsRef.current?.seg === segmentId) {
          return comparisonSpeedsRef.current.value;
        }
      }
      return undefined;
    },
    [comparison, comparisonSpeedData, chartDataExpander, targetDate, userProject]
  );
  // console.log(`###  memoized comparisonSpeeds ${JSON.stringify(comparisonSpeeds)}`);

  // memoize thresholds
  const slowdownBands = useMemo(
    () => chartDataExpander?.slowdownBands(segmentSpeeds, comparisonSpeeds, slowdownThreshold),
    [segmentSpeeds, comparisonSpeeds, slowdownThreshold, chartDataExpander]
  );

  // memoize freeflowSpeedFlatline
  const freeflowSpeedFlatline = useMemo(
    () => {
      // Hide the flatline until segment speeds data has loaded
      if (segmentSpeedsRef.current?.date === targetDate) {
        return chartDataExpander?.flatline(
          convertSpeeds(segmentProperties?.freeflow_speed_mph, userProject.uses_metric)
        );
      } else {
        return undefined;
      }
    },
    [segmentProperties, chartDataExpander, segmentSpeeds]
  );

  // update chart with new targetDate, freeflowSpeeds, segmentSpeeds
  useEffect(() => {
    setChartOptions({
      xAxis: {
        plotBands: slowdownBands,
        plotLines: [
          {
            ...dayMarkerLineBase,
            value: dayjs(targetDate)
          },
          {
            ...dayMarkerLineBase,
            value: dayjs(targetDate).add(1, 'day')
          }
        ],
      },
      yAxis: {
        title: {
          text: `Average Speed (${speedUnit(userProject.uses_metric)})`
        }
      },
      series: [
        {
          id: 'freeflow',
          data: freeflowSpeedFlatline || [],
          marker: { enabled: false },
        },
        {
          id: 'typical_speed',
          name: formatTypicalSeriesName(comparison, targetDate),
          data: comparisonSpeeds || [],
          marker: { enabled: false },
        },
        {
          id: 'segment_speed',
          data: segmentSpeeds || [],
          name: formatTargetSeriesName(targetDate),
          marker: { enabled: false },
        },
      ],
      tooltip: {
        formatter: makeFormatTooltip(
          slowdownBands,
          speedData?.bin_size,
          (v) => `${formatSpeed(v, userProject.uses_metric, true)}`,
          targetDate,
          comparison
        )
      }
    });
  }, [segmentSpeeds, comparisonSpeeds, freeflowSpeedFlatline, slowdownBands, speedData]);

  useEffect(() => {
    const chart = chartComponent.current?.chart;
    if (chart) {
      chart.reflow();
    }
  }, [maximize]);

  return (
    <div className={styles.chart}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        options={chartOptions}
      />
    </div>
  );
}
