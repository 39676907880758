import { useSelector } from 'react-redux';
import {
  selectComparison,
  selectProject,
  selectTargetDate,
} from '../../state/workflowSlice';
import { useGetTimelineSummaryQuery } from '../../state/apiSlice';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';

export function useActivityTimeseries() {
  const targetDate = useSelector(selectTargetDate);
  const comparison = useSelector(selectComparison);
  const project_slug = useSelector(selectProject);

  const { currentData: timelineMetrics, isFetching, isLoading } = useGetTimelineSummaryQuery(
    { project_slug, date: targetDate, typicalMonth: isComparisonMonth(comparison) ? comparison : undefined },
    { skip: !project_slug || !targetDate }
  );

  let timeseries = timelineMetrics?.typRelCongestion;
  // if (timelineMetrics) {
  //   if ([TL_METRIC_ABS_SPEED].includes(timelineMetric)) {
  //     timeseries = timelineMetrics.avgCongestion;
  //   } else if ([TL_METRIC_TYP_REL_SPEED].includes(timelineMetric)) {
  //     timeseries = timelineMetrics.typRelCongestion;
  //   } else if ([TL_METRIC_ABS_VOL, TL_METRIC_TYP_REL_VOL].includes(timelineMetric)) {
  //     timeseries = timelineMetrics.typRelCounts;
  //   } else if ([TL_METRIC_TYP_REL_SPEED_VOL, TL_METRIC_FF_REL_SPEED_VOL].includes(timelineMetric)) {
  //     timeseries = timelineMetrics.typRelVhd;
  //   } else {
  //     timeseries = timelineMetrics.typRelVhd;
  //   }
  // }

  if (timeseries) {
    timeseries = [...timeseries];
    // Normalise the array
    const max = Math.max(...timeseries);
    const min = Math.min(...timeseries);
    for (let j = 0; j < timeseries.length; j++) {
      timeseries[j] -= min;
      timeseries[j] /= max - min;
    }
  }

  return { timeseries, isFetching, isLoading };
}
