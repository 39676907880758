import dayjs from 'dayjs';
import Highcharts from 'highcharts';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import {
  baseChartOptions,
  getBandMembership,
  grid_color,
  label_color,
  axis_color,
  font_size,
  freeflow_color,
  typical_color,
  recent_speed_color, graph_highlight_color
} from './chartOptions';
import { nrtXAxisOptions } from './nrtChartCommon';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const TICK_INTERVAL_HOUR = 1000 * 60 * 60; // milliseconds * seconds * minutes

// highcharts-react npm page
// https://www.npmjs.com/package/highcharts-react-official

// highcharts-react github repo
// https://github.com/highcharts/highcharts-react#readme

// highcharts documentation
// https://api.highcharts.com/highcharts/

export const speedChartOptions = {
  ...baseChartOptions,
  yAxis: {
    min: 0,
    lineColor: axis_color,
    gridLineColor: grid_color,
    gridLineWidth: 1,
    lineWidth: 1,
    tickWidth: 1,
    title: {
      text: 'Average Speed',
      style: {
        color: label_color,
      },
    },
    labels: {
      style: {
        color: label_color,
      },
    },
  },
  xAxis: {
    ...nrtXAxisOptions
  },
  series: [
    {
      id: 'freeflow',
      data: [],
      name: 'Freeflow speed',
      color: freeflow_color,
      lineWidth: 2,
      marker: { enabled: false },
    },
    {
      id: 'typical_speed',
      type: 'spline',
      data: [],
      name: 'Typical speed',
      color: typical_color,
      lineWidth: 2,
      marker: { enabled: false, symbol: 'circle' },
    },
    {
      id: 'segment_speed',
      type: 'spline',
      data: [],
      name: 'Segment speed',
      color: recent_speed_color,
      lineWidth: 2,
      marker: { enabled: false, symbol: 'circle' },
    },

  ],
};
