import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { ModeEdit } from '@mui/icons-material';

import {
  kDefaultCapacityLanes,
  kDefaultCapacityThreshold,
  selectCapacityLanes,
  selectCapacityThreshold,
  setCapacityLanes,
  setCapacityThreshold
} from 'state/workflowSlice';

import styles from 'features/task_bar/Taskbar.module.css';
import { Input } from '@mui/material';
import Button from '@mui/material/Button';

const lanesMax = 10;
const lanesMin = 1;

export function CapacityThresholdEditor() {
  const threshold = useSelector(selectCapacityThreshold);
  const lanes = useSelector(selectCapacityLanes);
  const dispatch = useDispatch();
  const [thresholdNum, setThresholdNum] = React.useState(threshold);
  const [lanesNum, setLanesNum] = React.useState(lanes || kDefaultCapacityLanes);
  const [oldThreshold, setOldThreshold] = React.useState(threshold);

  // Detect changes to the underlying threshold state, otherwise this value gets stuck when you change project
  if (threshold !== thresholdNum && oldThreshold !== threshold) {
    setOldThreshold(threshold);
    setThresholdNum(threshold);
  }

  function setThreshold(newValue: any) {
    dispatch(setCapacityThreshold(newValue));
  }

  const handleInputChange = (evt) => {
    setThresholdNum(evt.target.value);
  };

  function setLanes(newValue: any) {
    newValue = Math.min(newValue, lanesMax);
    newValue = Math.max(newValue, lanesMin);
    setLanesNum(newValue);
    dispatch(setCapacityLanes(newValue));
  }

  const handleLanesInputChange = (evt) => {
    const newValue = Number(evt.target.value);
    setLanesNum(evt.target.value);
    if (newValue) {
      setLanes(newValue);
    }
  };

  const handleLanesIncrement = (evt) => {
    setLanes(lanes + 1);
  };

  const handleLanesDecrement = (evt) => {
    setLanes(lanes - 1);
  };

  const handleBlur = () => {
    const newValue = Math.abs(Number(thresholdNum) || kDefaultCapacityThreshold);
    setThreshold(newValue);
    setThresholdNum(String(newValue));
  };

  const handleLanesBlur = (evt) => {
    const newValue = Number(evt.target.value);
    if (newValue) {
      setLanes(newValue);
    } else {
      setLanes(kDefaultCapacityLanes);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  return (
    <div className={styles.task_bar_component}>
      <InputLabel
        id="capacity-threshold-input-label"
        style={{ alignItems: 'center' }}
      >
        Lane
        <br />
        Capacity:
        <TextField
          className={styles.task_bar_textfield}
          size="small"
          variant="filled"
          type="thresholdNumber"
          id="capacity"
          color="primary"
          value={thresholdNum}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ModeEdit color="primary" fontSize="inherit" />
              </InputAdornment>
            ),
          }}
        />
      </InputLabel>
      <InputLabel
        id="capacity-threshold-input-label"
        style={{ alignItems: 'center' }}
      >
        Lanes:
        <div
          className={styles.task_bar_combifield}
        >
          <Input
            className={styles.task_bar_input}
            size="small"
            type="thresholdNumber"
            id="capacity"
            color="primary"
            value={lanesNum}
            onChange={handleLanesInputChange}
            onBlur={handleLanesBlur}
            inputProps={{
              step: 1,
              min: lanesMin,
              max: lanesMax,
              type: 'number',
              'aria-labelledby': 'lanes-count',
            }}
          />
          <Button
            size="small"
            className={styles.lanes_buttons}
            onClick={handleLanesIncrement}
          >
            +
          </Button>
          <Button
            size="small"
            className={styles.lanes_buttons}
            onClick={handleLanesDecrement}
          >
            -
          </Button>
        </div>
      </InputLabel>
    </div>
  );
}
